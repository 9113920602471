import './App.css';
import Footer from './Footer';
import Header from './Header';
import Home from './HomePromised';
import CardDetails from "./CardDetails"; 
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'  

function App() {
  return (
    <Router>
    <div className="App">
      <Header />

      <Routes>

      <Route exact path="/" element={<Home />} />
      <Route path="/:id" element={<CardDetails />} />
      </Routes>


      <Footer />
    </div>
    </Router>
  );
}
 
export default App;
