import React from 'react';
import Logo from "./PokemonLogo.jpg";

class Header extends React.Component {
    constructor(props) {
        super(props)
        
    }

    render () {
        return(
        <div className="Header">
        <img src={Logo} alt="Pokemon Logo" className="Header-Logo"></img>
        <h1 className="Header-Text">Pokemon</h1>
        <h3 className="Header-Text"> Click on a card to learn more about the pokemon, if you would like to load more pokemon simply select load more at the bottom of the page</h3>
        </div>
        );
    }
}

export default Header;