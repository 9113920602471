import React, {Component} from 'react';
import './App.css';
import {useParams} from "react-router-dom";


function NameHook() {
    const {id} = useParams();
    console.log(id)
    return id
}

function attachName(Component){
    return function WrappedComponent(props){
        const id = NameHook();
        return <Component {...props} id = {id} />;
    }
}

class CardDetails extends Component {
    constructor(props) {
        super(props)

        this.getAPIData = this.getAPIData.bind(this);
        this.changeImgHover = this.changeImgHover.bind(this);
        this.state = {imgSrc: [], backimgSrc: [], hP: [], type: [], attack: [], speed: [], defense: [], move: [], moveTwo: [], backOn: false};
    }

    
    async getAPIData() {
      // This code is provided, it can be complicated
      const url = `https://pokeapi.co/api/v2/pokemon/${this.props.id}`; // URL of the API
      const response = await fetch(url); // Get the data from the PokeAPI
      const responseJSON = await response.json(); // Turn the data into a JSON object that we can use

      // This should also seem familiar
      this.setState(
          {
              imgSrc: responseJSON.sprites.front_shiny, // Add the pokemon we got from the API to the pokemon state response and then the path
              backimgSrc: responseJSON.sprites.back_shiny,
              hP: responseJSON.stats[0].base_stat,
              type: responseJSON.types[0].type.name,
              attack: responseJSON.stats[1].base_stat,
              move: responseJSON.moves[0].move.name,
              moveTwo: responseJSON.moves[1].move.name,
              defense: responseJSON.stats[2].base_stat,
              speed: responseJSON.stats[5].base_stat,
          }
      );
     
  }

  // Only use an API once after the page loads
  componentDidMount() {
      // Start getting the API data from PokeAPI
      this.getAPIData();
  }

  changeImgHover = () => {

      this.setState((prevState) => (
    {
        backOn: !prevState.backOn
    })
      );
  }

 
    render () {
        const card = <div className="card">
          {this.props.id}
          <img src={this.state.backOn?this.state.backimgSrc:this.state.imgSrc} alt="Pokemon" onMouseOver={this.changeImgHover} onMouseOut={this.changeImgHover}></img>
          <h3>HP: {this.state.hP}</h3>
          <h3>Type: {this.state.type} </h3>
          <h3>Main Move: {this.state.move} </h3>
          <h3>Secondary Move: {this.state.moveTwo} </h3>
          <h3>Attack: {this.state.attack} </h3>
          <h3>Defense: {this.state.defense} </h3>
          <h3>Speed: {this.state.speed} </h3>
          </div>
        
        return card;
    }
}

export default attachName(CardDetails);