import React from 'react';
import './App.css';

class Card extends React.Component {
    constructor(props) {
        super(props)

        this.getAPIData = this.getAPIData.bind(this);
        this.changeImgHover = this.changeImgHover.bind(this);
        this.state = {imgSrc: [], backimgSrc: [], hP: [], type: [], attack: [], speed: [], defense: [], move: [], backOn: false};
    }

    
    async getAPIData() {
      // This code is provided, it can be complicated
      const url = this.props.url; // URL of the API
      const response = await fetch(url); // Get the data from the PokeAPI
      const responseJSON = await response.json(); // Turn the data into a JSON object that we can use

      // This should also seem familiar
      this.setState(
          {
              imgSrc: responseJSON.sprites.front_shiny, // Add the pokemon we got from the API to the pokemon state response and then the path
              backimgSrc: responseJSON.sprites.back_shiny,
              hP: responseJSON.stats[0].base_stat,
              type: responseJSON.types[0].type.name,
              attack: responseJSON.stats[1].base_stat,
              move: responseJSON.moves[0].move.name,
          }
      );
     
  }

  // Only use an API once after the page loads
  componentDidMount() {
      // Start getting the API data from PokeAPI
      this.getAPIData();
  }

  changeImgHover = () => {

      this.setState((prevState) => (
    {
        backOn: !prevState.backOn
    })
      );
  }

    render () {
        const card = 
        <div className="card">
          {this.props.name}
          <img src={this.state.backOn?this.state.backimgSrc:this.state.imgSrc} alt="Pokemon" onMouseOver={this.changeImgHover} onMouseOut={this.changeImgHover}></img>
          <h3>HP: {this.state.hP}</h3>
          <h3>Type: {this.state.type} </h3>
          <h3>Main Move: {this.state.move} </h3>
          <h3>Attack: {this.state.attack} </h3>
          </div>
        
        return card;
    }
}

export default Card;