import React from 'react';
import Card from './Card';

class Home extends React.Component {
    constructor(props) {
        super(props)

        this.handleResponse = this.handleResponse.bind(this);
        this.handleError = this.handleError.bind(this);
        this.state = {pokemon: [], page: [0]};
    }

    handleFetch(response){
        return response.json();
    }

    handleResponse(response){
        const responsePokemon = response.results.map((item) => <div key={item.name}><a className="card-link"href={`/${item.name}`}><Card name={item.name} url={item.url} key={item.name}/></a></div>);

        this.setState((prevState) => (
            {
                pokemon: [prevState.pokemon, responsePokemon] // Add the pokemon to state
            }
        ));
    }

    handleError(error) {
        console.log(error);
        this.setState(
            {
                pokemon:<h1>Network Error! Failed to load please try again.</h1>
            }
        );
    }

    componentDidMount() {

        const { page } = this.state;
        const url = `https://pokeapi.co/api/v2/pokemon?limit=15&offset=${page}`; // url
        fetch(url)
        .then(this.handleFetch)
        .then(this.handleResponse)
        .catch(this.handleError)
    }

    handleClick = () => {
        this.setState((prevState) => {
            return {
              page: parseInt(prevState.page) + parseInt(15)
            };
          });       
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
          this.loadPoke();
        }
      }

      loadPoke = () => {
        const { page } = this.state;

        fetch(`https://pokeapi.co/api/v2/pokemon?limit=15&offset=${page}`)
        .then(this.handleFetch)
        .then(this.handleResponse)
        .catch(this.handleError)
      };

      handleCardClick = () => {

      }

    render () {

        const PokemonCards =( <div className="cardStyle">
            {this.state.pokemon}
            <button onClick={this.handleClick} className="load-more-but"> Load More! </button>
            </div>

        )

        return PokemonCards;
    }
}

export default Home;